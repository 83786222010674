// Note: Requires flexslider 2
jQuery(document).ready(function($) {
	$('.row-checkered-slider .flexslider').flexslider({
		animation: "slide",
		prevText: "",
		nextText: "",
		controlNav: false,
		directionNav: false,
		animationLoop: true,
	});

	$('.row-checkered-slider').each(function () {
		var $thisContainer = $(this);
		$thisContainer.find('.checkerboard-custom-prev').click(function () {
			$thisContainer.find('.flexslider').flexslider('next');
		});

		$thisContainer.find('.checkerboard-custom-next').click(function () {
			$thisContainer.find('.flexslider').flexslider('prev');
		});
	});
}); /* end of as page load scripts */